<template>
	<div id="checkList">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-input-group compact>
				<a-select v-model:value="search.h5_is_ok" style="width: 120px;">
					<a-select-option :value="0">全 部</a-select-option>
					<a-select-option :value="1">未审核</a-select-option>
					<a-select-option :value="2">已审核</a-select-option>
				</a-select>
				<a-input-search style="width: 300px;" v-model:value="search.key" placeholder="应用名称" enter-button @search="getProgramList(1, info.limit)" />
			</a-input-group>
		</div>

		<div class="el-content">
			<a-table :pagination="false" row-key="program_id" :data-source="info.list" :columns="[
				{title:'应用',dataIndex:'apps',slots:{customRender:'apps'}},
				{title:'H5地址',dataIndex:'h5_url'},
				{title:'h5审核状态',dataIndex:'h5_is_ok',slots:{customRender:'h5_is_ok'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}}
			]" >
				<template #apps="{record}">
					<div class="kd-app-name">
						<img v-if="record.logo" :src="record.logo" />
						<img v-else src="../../../assets/img/moren.png" />
						<div class="kd-app-name-info">
							<div>{{ record.program_name }}</div>
							<div>APP ID:{{ record.program_id }}</div>
						</div>
					</div>
				</template>
				<template #h5_is_ok="{record}">
					<a-tag :color="record.h5_is_ok == 1 ?'#00CC66':'#999'">{{record.h5_is_ok == 1 ?'已审核':'未审核'}}</a-tag>
				</template>
				<template #action="{record}">
					<a-button
						v-has="{ action: 'sass_app_agree_check' }"
						v-if="record.h5_is_ok == 0"
						@click="passCheck(record.program_id)"
						type="primary"
					>
						通过审核
					</a-button>
					<a-button v-else type="link">已审核</a-button>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange=" (page, e) => { getProgramList(info.page, e); } "
					@change=" e => { getProgramList(e, info.limit); } "
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import programModel from '@/api/saas/app';
export default {
	setup(){
		const state = reactive({
			search: {
				key: '',
				h5_is_ok: 0 //0全部 2已通过 1待审核
			},
			info: {
				list: [],
				page: 1,
				limit: 10,
				count: 0
			}
		})
		getProgramList(1,state.info.limit)

		function getProgramList(page,limit){
			programModel.getH5Program(page,limit,state.search,res=>state.info = {limit,...res})

		}

		const passCheck = program_id =>programModel.h5ProgramCheck(program_id,res=>{
			getProgramList(state.info.page,state.info.limit)
		})


		return{
			...toRefs(state),
			getProgramList,
			passCheck
		}
	}
}
</script>

<style lang="scss">
.app-search-input {
	width: 350px;
}
.app-sselect {
	width: 300px !important;
}
.kd-app-name {
	width: 100%;
	height: auto;
	display: flex;

	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-right: 10px;
	}
}
</style>
